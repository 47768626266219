/* Add Global CSS code that would customize Topigon styling in CSS */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* https://github.com/sahibjotsaggu/San-Francisco-Pro-Fonts */
@font-face {
  font-family: "SF Pro Text";
  src: url("/public/fonts/SF-Pro-Text-Heavy.otf");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "SF Pro Text";
  src: url("/public/fonts/SF-Pro-Text-Bold.otf");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "SF Pro Text";
  src: url("/public/fonts/SF-Pro-Text-Semibold.otf");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "SF Pro Text";
  src: url("/public/fonts/SF-Pro-Text-Medium.otf");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "SF Pro Text";
  src: url("/public/fonts/SF-Pro-Text-Regular.otf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "SF Pro Text";
  src: url("/public/fonts/SF-Pro-Text-Light.otf");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "SF Pro Text";
  src: url("/public/fonts/SF-Pro-Text-Thin.otf");
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: "SF Pro Text";
  src: url("/public/fonts/SF-Pro-Text-Ultralight.otf");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "Share Tech Mono";
  src: url("/public/fonts/ShareTechMono-Regular.ttf");
  font-style: normal;
}

body {
  font-family: "SF Pro Text", sans-serif;
  color: #0e0e0e;
}

.bg-gradient {
  position: relative;
  background: linear-gradient(217.61deg, #f74c06 15.28%, #f9bc2c 85.43%)
    border-box;
  background-clip: border-box; /* Ensures the gradient is applied to the border */
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}
